export async function AddStudent(userdata) {
  try {
    console.log("Add Student API Called");
    console.log(userdata);

    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user/singup`, {
      method: "POST",
      body: JSON.stringify(userdata),
      headers: { "content-type": "application/json" },
    });

    // Check if the response is OK
    if (!response.ok) {
      throw new Error(`Failed to add student: ${response.statusText}`);
    }

    const data = await response.json();
    return { data };
  } catch (error) {
    console.error("Error in AddStudent API:", error);
    throw new Error("Failed to add student");
  }
}

export async function GetAllStudents() {
  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/student`, {
      method: "GET",
      headers: { "content-type": "application/json" },
    });

    // Check if the response is OK
    if (!response.ok) {
      throw new Error(`Failed to fetch students: ${response.statusText}`);
    }

    const data = await response.json();
    console.log(data);
    return { data };
  } catch (error) {
    console.log("Error in GetAllStudents API:", error);
    throw new Error("Failed to fetch students");
  }
}

export async function DeleteStudents(userdata) {
  return new Promise(async (resolve,reject) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/student/delete`,
        {
          method: "DELETE",
          body: JSON.stringify(userdata),
          headers: { "content-type": "application/json" },
        }
      );
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
      } else {
        const err = await response.text();
        reject(err);
      }
    } catch (err) {
      console.log(err);
    }
  });
}
