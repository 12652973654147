export async function AddVideos(userdata) {
  try {
    console.log(userdata);
    // Make sure the FormData object is used if sending file data
    // Sending the POST request with the form data
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/video/add`,
      {
        method: "POST",
        body: userdata, // sending the FormData object
      }
    );

    // Check if response is OK
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    // Parse the response data
    const data = await response.json();
    return { data };
  } catch (error) {
    // Handle and log any errors
    console.error("Error in AddVideos API:", error);
    throw new Error("Failed to add video"); // or return a custom error message
  }
}

export async function DeleteVideos(userdata) {
  return new Promise(async (resolve,reject) => {
    try {
      console.log(userdata);
      
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/class/delete`,
        {
          method: "DELETE",
          body: JSON.stringify({id:userdata}),
          headers: { "content-type": "application/json" },
        }
      );
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
      } else {
        const err = await response.text();
        reject(err);
      }
    } catch (err) {
      console.log(err);
    }
  });
}
